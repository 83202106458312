import { combineDateTime } from '../../utils/modelHelper';
import { formatDate } from '../../utils/formatter';
import { DATETIME_FORMAT } from '../../constants/constants';
import { gatewayApi } from '../gatewayApi';

export type DocumentSide = 'FRONT' | 'BACK' | 'FACE' | 'UTILITY_BILL';

export interface KycDocumentMetadata {
  size: string;
  type: string;
}

const kycDocumentsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getKycDocumentsByCheck: build.query({
      query: (checkId) => `kyc/documents?checkId=${checkId}`,
      providesTags: (result, error, checkId) => [
        { type: 'KycDocumentsByCheck', checkId },
      ],
      transformResponse: (results) =>
        (results as any[]).map((item) => {
          let result = { ...item };
          result = combineDateTime(result, formatDate, DATETIME_FORMAT);
          return result;
        }),
    }),

    getKycDocumentDownloadLink: build.query({
      query: (documentId) => `kyc/documents/${documentId}/download-link`,
    }),

    deleteKycDocument: build.mutation({
      query: ({ documentId }) => ({
        url: `kyc/documents/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { checkId }) => [
        { type: 'KycDocumentsByCheck', checkId },
      ],
    }),

    uploadKycDocument: build.mutation<
      KycDocumentMetadata,
      {
        formData: FormData;
        applicantId: string;
        side: DocumentSide;
        type: string;
        issuingCountry: string;
      }
    >({
      query({ formData, applicantId, side, type, issuingCountry }) {
        formData.append('applicantId', applicantId);
        formData.append('side', side);
        formData.append('type', type);
        formData.append('issuingCountry', issuingCountry);

        return {
          url: `kyc/documents`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, { applicantId }) => [
        { type: 'KycDocumentsByCheck', applicantId },
      ],
    }),
  }),
});

export const {
  useGetKycDocumentsByCheckQuery,
  useLazyGetKycDocumentDownloadLinkQuery,
  useDeleteKycDocumentMutation,
  useUploadKycDocumentMutation,
} = kycDocumentsApi;

export const useGetKycDocumentDownloadLinkQueryState =
  kycDocumentsApi.endpoints.getKycDocumentDownloadLink.useQueryState;
