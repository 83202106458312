import { gatewayApi } from '../gatewayApi';

const kycApplicantsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getKycApplicants: build.query({
      query: (params) => ({
        url: 'kyc/applicants',
        params,
      }),
      providesTags: (result, error, params) => [{ type: 'KycApplicants', params }],
    }),
    getKycApplicant: build.query({
      query: ({ id }) => `kyc/applicants/${id}`,
      providesTags: (result, error, id) => [{ type: 'KycApplicants', id }],
    }),

    addKycApplicantWithProvider: build.mutation({
      query: ({ provider, applicant: body }) => ({
        url: `kyc/applicants/with-provider/${provider}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, params) => [{ type: 'KycApplicants', params }],
    }),

    addKycApplicantsWithProvider: build.mutation({
      query: ({ provider, applicants: body }) => ({
        url: `kyc/applicants/with-provider/${provider}/bulk`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, params) => [{ type: 'KycApplicants', params }],
    }),

    deleteKycApplicant: build.mutation({
      query: (applicantId) => ({
        url: `kyc/applicants/${applicantId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) => [{ type: 'KycApplicants', params }],
    }),

    // depricated EP, use setManualKycIndividualStatus instead
    updateKycManualDecision: build.mutation<any, any>({
      query({ applicantId, decision }) {
        return {
          url: `/kyc/applicants/${applicantId}/manual-decision/${decision}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { applicantId, params }) => [
        { type: 'KycApplicants', params },
        { type: 'KycApplicant', id: 'LIST' },
      ],
    }),

    setManualKycIndividualStatus: build.mutation<
      any,
      { kycApplicantId: string; manualDecisionReason: string; manualResult: 'CLEAR' | 'CONSIDER' }
    >({
      query: ({ kycApplicantId, manualResult, manualDecisionReason }) => ({
        url: `/kyc/applicants/${kycApplicantId}/manual-decision`,
        method: 'POST',
        body: { manualResult, manualDecisionReason },
      }),
      invalidatesTags: [{ type: 'Individuals', id: `LIST` }],
    }),

    deleteManualKycIndividualStatus: build.mutation<any, { kycApplicantId: string }>({
      query: ({ kycApplicantId }) => ({
        url: `/kyc/applicants/${kycApplicantId}/manual-decision`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Individuals', id: `LIST` }],
    }),
  }),
});

export const {
  useGetKycApplicantsQuery,
  useGetKycApplicantQuery,
  useAddKycApplicantWithProviderMutation,
  useAddKycApplicantsWithProviderMutation,
  useDeleteKycApplicantMutation,
  useUpdateKycManualDecisionMutation,

  useDeleteManualKycIndividualStatusMutation,
  useSetManualKycIndividualStatusMutation,
} = kycApplicantsApi;
