import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  ApplicationFormStatus,
  ApplicationRecord,
  getFormStatusesBySectionIdAndPartition,
  useGetApplicationRecordsByApplicationIdQuery,
} from '@haystack-forms/api';
import { CustomTab, CustomTabProps } from './CustomTab';
import { CustomTabs } from './CustomTabs';
import { Section } from '../../../../../onboarding/common/types';

const Submenu = styled('div', {
  name: 'Submenu',
  slot: 'root',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '8px 0 0 20px',
}));

const StyledSubmenuItem = styled('div', {
  name: 'StyledSubmenuItem',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    color: 'grey.600',
    fontSize: '14px',
    lineHeight: '20px',
    padding: '3px 0',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    '&:hover': {
      color: 'grey.900',
    },
  }),
);

interface TabLabelProps {
  label: string;
  status: string;
  actualStatus?: string;
  color: string;
  subtitles?: string[];
  handleSubmenuItemClick?: (subtitle: string) => void;
}

export const TabLabel: React.FC<TabLabelProps> = ({
  label,
  status,
  actualStatus,
  color,
  subtitles = [],
  handleSubmenuItemClick,
}) => (
  <Box display="flex" sx={{ flexDirection: 'column', gap: 1 }}>
    <Typography variant="body" color="text.base">
      {label}
    </Typography>
    <Typography variant="bodySmall" color={color}>
      {status}
    </Typography>
    {actualStatus && subtitles.length > 0 && handleSubmenuItemClick && (
      <Submenu>
        {subtitles.map((subtitle, idx) => (
          <StyledSubmenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${subtitle}-${idx}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmenuItemClick(subtitle);
            }}
          >
            {subtitle}
          </StyledSubmenuItem>
        ))}
      </Submenu>
    )}
  </Box>
);

interface FormTabsListProps {
  handleChange: (value: string) => void;
  tabValue: string;
  section: Section;
  applicationId: string;
}

export const FormTabsList = ({
  section,
  tabValue,
  handleChange,
  applicationId,
}: FormTabsListProps): JSX.Element => {
  const [formsSubtitles, setFormsSubtitles] = React.useState<string[][]>([]);

  const { data: allRecords } = useGetApplicationRecordsByApplicationIdQuery(applicationId, {
    skip: !applicationId,
    selectFromResult: ({ data }) => ({
      data: data?.entities,
    }),
  });

  const sortFormFields = ({
    formArr,
    parentId = undefined,
  }: {
    formArr: ApplicationRecord[];
    parentId?: string;
  }): ApplicationRecord[] => {
    const filteredAndSorted = formArr
      .filter((item) => item.parent === parentId)
      .sort((a, b) => a.row - b.row || a.col - b.col);

    return filteredAndSorted.reduce((acc, item) => {
      acc.push(item);
      const children = sortFormFields({ formArr, parentId: item.id });
      acc.push(...children);
      return acc;
    }, [] as ApplicationRecord[]);
  };

  React.useEffect(() => {
    const calculatedFormsSubtitles = section.forms.map((f) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const sortedRecords = sortFormFields({ formArr: f.records, parentId: undefined });
      return sortedRecords
        .filter((r, index, array) => {
          const isNextKeyValue = array[index + 1] && array[index + 1].field.type === 'key-value';
          return r.field.type === 'subtitle' && !r.parent && !isNextKeyValue;
        })
        .map((r) => r.field.title);
    });
    setFormsSubtitles(calculatedFormsSubtitles);
  }, [section.forms]);

  const handleSubmenuItemClick = (subtitle: string) => {
    const allSubtitles = Array.from(document.querySelectorAll('h2'));
    const targetElement = allSubtitles.find((element) => element.textContent === subtitle);
    if (targetElement) {
      const headerOffset = 0;
      const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const formStatuses = useSelector(
    getFormStatusesBySectionIdAndPartition(section.id, section.partitionName),
  );
  const formsTabValues = section.forms.map(({ id }, idx) => ({
    formId: id,
    tabValue: `0${idx}`,
  }));
  const { formId } = formsTabValues.find((ftv) => ftv.tabValue === tabValue);

  const getTabValue = (forFormId: string) =>
    formsTabValues.find((el) => el.formId === forFormId)?.tabValue || '00';

  const getTabs = (): CustomTabProps[] => {
    if (section && allRecords) {
      return section.forms?.flatMap((form, idx) => {
        const { status, actualStatus } = formStatuses.find((s) => s.id === form.id) || {};
        const statusColor =
          status === ApplicationFormStatus.Errors ? 'text.danger' : 'text.primary';
        const subtitles = idx === parseInt(tabValue, 10) ? formsSubtitles[idx] : [];
        const isActive = formId ? formId === form.id : idx === 0;
        const parentTab = {
          id: form.id,
          title: form.title,
          status,
          actualStatus,
          indicatorColor: statusColor,
          onClick: () => {
            handleChange(getTabValue(form.id));
          },
          isActive,
        };
        const subTabs =
          subtitles?.length > 0
            ? subtitles.map((subtitle, index) => ({
                title: subtitle,
                onClick: () => handleSubmenuItemClick(subtitle),
                isSubTab: true,
              }))
            : [];
        return [parentTab, ...subTabs];
      });
    }
    return [];
  };

  return (
    <Box className="FormTabsList" sx={{ display: 'flex', flexDirection: 'column', pr: 8 }}>
      {section && allRecords && (
        <CustomTabs className="CustomTabs" sx={{ display: 'flex', flexDirection: 'column' }}>
          {getTabs().map((tab) => (
            <CustomTab key={`${tab.title}-${tab.id}`} {...tab} />
          ))}
        </CustomTabs>
      )}
    </Box>
  );
};
