import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  CopyAll as CopyAllIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import { downloadFile } from 'utils/downloadFile';
import { toastCopySuccess, toastCopyError } from 'utils/toast';
import {
  useDeleteKycDocumentMutation,
  useGetKycDocumentsByCheckQuery,
  useLazyGetKycDocumentDownloadLinkQuery,
} from 'services/gatewayApi/kycDocumentsApi';
import { ConfirmationDialog } from 'components';
import { DescriptionTags } from '../../../DescriptionTags';
import { KycCheck } from '../../../../types';

interface KycCheckDocumentsProps {
  kycCheck: KycCheck;
  isOpen: boolean;
}

export const KycCheckDocuments: React.FC<KycCheckDocumentsProps> = ({
  kycCheck,
  isOpen = false,
}) => {
  const { id, extDescription } = kycCheck;
  const {
    data: documents,
    isLoading,
    isFetching,
  } = useGetKycDocumentsByCheckQuery(id, { skip: !isOpen });

  const getMime = (mime) => {
    const mimeTypes = [
      { ext: '.png', mime: 'image/png' },
      { ext: '.jpg', mime: 'image/jpeg' },
      { ext: '.gif', mime: 'image/gif' },
      { ext: '.pdf', mime: 'application/pdf' },
    ];

    return mimeTypes.find((m) => m.mime === mime) || { ext: '', mime };
  };

  const [downloadingDocIds, setDownloadingDocIds] = useState<Array<string>>([]);
  const [getLink] = useLazyGetKycDocumentDownloadLinkQuery();
  const downloadDocumentFile = ({ fileName, fileType, id }) => {
    setDownloadingDocIds((oldArray) => [...oldArray, id]);

    getLink(id).then(({ data }) => {
      const downloadLink = data?.url ? data.url : `/api/gateway/kyc/documents/${id}/download`;

      downloadFile(downloadLink, { fileName: `${id} - ${fileName}${getMime(fileType).ext}` })
        .catch((rejected) => {
          console.error(rejected);
        })
        .finally(() => {
          setDownloadingDocIds((oldArray) => [...oldArray.filter((d) => d !== id)]);
        });
    });
  };

  // #region Deleting functionality
  const [deleteKycDocument] = useDeleteKycDocumentMutation();
  const [idToDelete, setIdToDelete] = useState('');

  const handleDelete = (id) => {
    setIdToDelete(id);
  };

  const confirmDelete = () => {
    deleteKycDocument({
      documentId: idToDelete,
      checkId: id,
    })
      .unwrap()
      .catch((rejected) => {
        console.error(rejected);
      })
      .finally(() => {
        setIdToDelete('');
      });
  };
  // #endregion

  return (
    <>
      <TableRow>
        <TableCell colSpan={5} sx={{ borderBottom: 'none', p: 0 }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <DescriptionTags description={extDescription} />

            {isLoading || isFetching ? (
              <LinearProgress />
            ) : (
              <>
                <Box className="text-center">
                  <Typography variant="h3" sx={{ my: 2 }}>
                    Documents
                  </Typography>
                </Box>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>ID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Side</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents?.length > 0 ? (
                      documents.map((doc) => (
                        <TableRow key={`doc_${doc.id}`}>
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={() => {
                                navigator.clipboard.writeText(doc.id).then(
                                  () => toastCopySuccess('Document ID'),
                                  () => toastCopyError('Document ID'),
                                );
                              }}
                            >
                              <CopyAllIcon style={{ fontSize: '16px' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell>{doc.side}</TableCell>
                          <TableCell>{doc.status}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => downloadDocumentFile(doc)}
                              size="large"
                              disabled={downloadingDocIds.includes(doc.id)}
                            >
                              <GetAppIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(doc.id)} size="large">
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Typography variant="body1" align="center" component="div">
                            No matching records found...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      <ConfirmationDialog
        open={!!idToDelete}
        onClose={() => setIdToDelete('')}
        onCancel={() => setIdToDelete('')}
        onConfirm={confirmDelete}
        title="Deleting KYC Document"
        text="Are you sure you want to permanently delete selected KYC Document?"
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        cancelButtonText="Cancel"
      />
    </>
  );
};
