import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getUser } from '../keycloak';

// Returns updated headers (if passed)
export const getKCHeaders = (h?: Record<string, string>): Record<string, string> => {
  const keycloakUser = getUser();
  const headers: Record<string, string> = h ? { ...h } : {};
  if (keycloakUser && keycloakUser.profile) {
    const { access_token: token, token_type: tokenType, profile } = keycloakUser;
    const { tenantId } = profile;

    const user = localStorage.getItem('user');
    const selectedProductId = user ? JSON.parse(user).selectedProductId : undefined;

    token && (headers.Authorization = `${tokenType} ${token}`);
    tenantId && (headers['X-TENANT-ID'] = selectedProductId || `${tenantId}`);
  }

  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/gateway/',
  prepareHeaders: (headers) => {
    Object.entries(getKCHeaders()).forEach((header) => {
      headers.set(header[0], header[1]);
    });
    return headers;
  },
});

const customFetchBase = async (args, api, extraOptions) => baseQuery(args, api, extraOptions);

export const gatewayApi = createApi({
  reducerPath: 'dispatchApi',
  tagTypes: [
    'Acquirer',
    'Acquirers',
    'Application',
    'Applications',
    'ApplicationHistory',
    'ApplicationNote',
    'ApplicationMessage',
    'ApplicationCustomer',
    'ApplicationEvents',
    'ApplicationShareholder',
    'ApplicationStates',
    'ApplicationStatesNext',
    'ApplicationsUnreadMessages',
    'ApplicationEmails',
    'ApplicationForms',
    'ApplicationFormTag',
    'AppCategories',
    'Case',
    'CaseAssignments',
    'CaseCategory',
    'CaseComments',
    'CasePredefinedComments',
    'CardData',
    'Client',
    'Clients',
    'ClientsCategories',
    'ClientIbans',
    'ClientIbansFinActivity',
    'ClientAccountTypes',
    'ClientAccounts',
    'Countries',
    'Dashboards',
    'Departments',
    'EmbRiskScoring_Answers',
    'EmbRiskScoring_AnswersTotalScore',
    'EmbRiskScoring_Indicators',
    'FormSections',
    'FormSectionsHistory',
    'FormSectionStatuses',
    'FormTemplateFields',
    'Individuals',
    'OnboardingRiskCountries',
    'ClientRiskCountries',
    'ClientRiskCountryHistory',
    'EeaRiskCountries',
    'EeaRiskCountryHistory',
    'RiskCountries',
    'OnboardingRiskCountryHistory',
    'RiskCountryHistory',
    'DictionaryList',
    'Dictionary',
    'ExceptionRules',
    'KycApplicants',
    'KycAllowedCountries',
    'KycChecksForApplication',
    'KycDocuments',
    'KycDocumentsByCheck',
    'KycProviders',
    'Merchants',
    'MerchantCategories',
    'RiskWords',
    'RuleDescriptions',
    'RuleVetos',
    'Ubo',
    'Ubos',
    'UboHistory',
    'UboLinkedApplications',
    'Users',
    'User',
    'CustomerUsers',
    'CustomerUser',
    'Group',
    'Groups',
    'Tenants',
    'OpenpaydUsers',
    'PepsanctionsSessions',
    'PepsanctionsSession',
    'PepsanctionsResults',
    'PepsanctionsWhitelist',
    'PepsanctionsTransactionSessions',
    'PepsanctionsTransactionResults',
    'Permissions',
    'WebsiteChecklists',
    'ResponseCode',
    'ResponseCodes',
    'RiskScoring_Sections',
    'RiskScoring_Options',
    'RiskScoring_Questions',
    'RiskScoring_Scores',
    'RiskScoring_Answers',
    'RiskScoring_AnswersTotalScore',
    'Selectors',
    'Selector',
    'Sessions',
    'SessionEntries',
    'SessionEntry',
    'SessionResults',
    'CreditsafeCountries',
    'CreditsafeCompanies',
    'HaystackClient',
  ],
  baseQuery: customFetchBase,
  endpoints: () => ({}),
});
