import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import { Log } from 'oidc-client-ts';
import { setApiConfig, setFormsConfig } from '@haystack-forms/api';
import { injectStore } from '@haystack-forms/ddforms';
import { fetchUserInfo, fetchUsers } from '../../actions/users';
import { fetchPermissions } from '../../actions/permissions';
import { fetchEnvironment } from '../../actions/environment';
import { fetchProducts } from '../../actions/products';
import { AppHeader } from '../../uikit/AppHeader/AppHeader';
import { userManager } from '../../keycloak';
import { getKCHeaders } from '../../services/gatewayApi';
import { getStore } from '../../store';
import '../../styles/scrollbar-replacement.css';
import {
  formsApiConfig,
  formsFeatureConfig,
} from '../../features/ApplicationManagement/ClientDetails/Forms/CPForms';

export const App = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const configureForms = async () => {
    if (await userManager.getUser()) {
      const authHeaders = getKCHeaders();
      dispatch(setApiConfig({ authHeaders, ...formsApiConfig }));
      dispatch(setFormsConfig(formsFeatureConfig));
    }
  };

  React.useEffect(() => {
    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);
    injectStore(getStore());
    configureForms();
  }, []);

  React.useEffect(() => {
    window.addEventListener('storage', async (e) => {
      if (e.key === 'logoutInitiated' && e.newValue === 'true') {
        await auth.signoutRedirect();
      }
    });
  }, []);

  React.useEffect(() => {
    if (auth.user) {
      dispatch(fetchUserInfo());
      dispatch(fetchEnvironment());
      dispatch(fetchPermissions());
      dispatch(fetchUsers());
      dispatch(fetchProducts());
    }
  }, [auth.user]);

  React.useEffect(() => {
    auth.events.addUserLoaded((user) => {
      localStorage.setItem('token_data', JSON.stringify(user.profile));
      configureForms();
    });
  }, []);

  React.useEffect(
    () =>
      auth.events.addAccessTokenExpired(async () => {
        await auth.removeUser();
        sessionStorage.clear();
        localStorage.setItem('logoutInitiated', 'true');
        localStorage.removeItem('logoutInitiated');
        await auth.signoutRedirect();
      }),
    [],
  );

  const activityListener = async () => {
    localStorage.setItem('activityDetected', 'true');
    localStorage.removeItem('activityDetected');
    await auth.signinSilent();
  };

  React.useEffect(() => {
    process.env.NODE_ENV !== 'development' &&
      auth.events.addAccessTokenExpiring(() => {
        window.addEventListener('mousemove', activityListener, { passive: true, once: true });
      });
    return window.removeEventListener('mousemove', activityListener);
  }, []);

  React.useEffect(() => {
    window.addEventListener(
      'storage',
      async (e) => {
        if (e.key === 'activityDetected' && e.newValue === 'true') {
          await auth.signinSilent();
          window.removeEventListener('mousemove', activityListener);
        }
      },
      { passive: true },
    );
    return window.removeEventListener('mousemove', activityListener);
  }, []);

  if (!auth.isAuthenticated && auth.isLoading) {
    return <div />;
  }

  if (auth.error) {
    setTimeout(() => {
      auth.signinRedirect();
    }, 1000);
    return <div />;
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect();
    return <div />;
  }

  return (
    <div className="app">
      <Helmet titleTemplate="%s - Haystack Backoffice" defaultTitle="Haystack Backoffice">
        <meta name="description" content="Meta Tagging" />
      </Helmet>
      <AppHeader />
      <Outlet />
    </div>
  );
};
