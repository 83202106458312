import React, { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import { Box, IconButton, LinearProgress, Tooltip, Grid, Typography, Chip } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { formatDateScreen } from 'utils';
import { ConfirmationDialog, MUIDatatableSkeleton } from '../../components';
import MUIDataTable from '../../components/MUIDataTable';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { cleanExceptionRuleData, resultActions, ruleTypes } from './constants';
import {
  useDeleteExceptionRuleMutation,
  useGetExceptionRulesQuery,
} from '../../services/gatewayApi/exceptionRulesApi';
import { ExceptionRuleDialog } from './ExceptionRuleDialog';

interface ExceptionRulesListProps {
  filterValues: any;
  productId: number;
}

export const ExceptionRulesList: React.FC<ExceptionRulesListProps> = ({
  filterValues,
  productId,
}) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const [idToDelete, setIdToDelete] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
  const [sortOrder, setSortOrder] = useState({
    name: 'createdDateTime',
    direction: 'desc',
  });
  const [exceptionRuleFormOpen, setExceptionRuleFormOpen] = useState(false);
  const [dataExceptionRule, setDataExceptionRule] = useState(cleanExceptionRuleData);

  const [deleteExceptionRule] = useDeleteExceptionRuleMutation();
  const {
    data = { results: [] },
    isLoading,
    isFetching,
  } = useGetExceptionRulesQuery(
    {
      ...filterValues,
      productId,
      page,
      size: pageSize,
      sort: `${sortOrder.name},${sortOrder.direction}`,
    },
    { skip: !productId },
  );

  useEffect(() => {
    if (data && data.paginationInfo) {
      setCount(data.paginationInfo.totalItemsCount);
      if (Math.floor((data.count - 1) / pageSize) < page) {
        resetGridPage(); // in case page is not available (after delete)
      }
    }
  }, [data, page, pageSize]);

  const exceptionRules = [...data.results];

  const handleEditForm = (id) => {
    if (id === 'new') {
      setDataExceptionRule((actual) => ({ ...actual, id }));
    } else {
      const selectedRule = data.results.find((c) => c.id === id);
      setDataExceptionRule(selectedRule);
    }
    setExceptionRuleFormOpen(true);
  };

  const AddButton = () => (
    <Tooltip title="Add new Exception Rule" disableFocusListener>
      <IconButton onClick={() => handleEditForm('new')}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  const columns = [
    {
      name: 'exceptionRuleType',
      label: 'Type',
      options: {
        customBodyRender: (value) => ruleTypes.find((el) => el.value === value).label,
      },
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'merchantName',
      label: 'Merchant',
      options: {
        display: productId === 1 ? 'true' : 'excluded',
        sort: false,
      },
    },
    {
      name: 'createdBy',
      label: 'Created by',
      options: {
        sort: false,
      },
    },
    {
      name: 'createdDateTime',
      label: 'Created on',
    },
    {
      name: 'expiryDateTime',
      label: 'Expiry',
      options: {
        customBodyRender: (value) => {
          if (value === '01-01-1970 10:00' || value === null || value === undefined) return '';
          const parsedDate = parseISO(value);
          const colorDate = () => {
            if (parsedDate < new Date()) return '#d32f2f';
            return '';
          };
          return (
            <Typography color={colorDate} variant="body2">
              {formatDateScreen(parsedDate)}
            </Typography>
          );
        },
      },
    },
    {
      name: 'referenceData',
      label: 'Reference data',
      options: {
        customBodyRenderLite: (value) => getReferenceDataField(value),
      },
    },
    {
      name: 'resultAction',
      label: 'Result action',
      options: {
        filter: false,
        customBodyRender: (value) => {
          const resultAction = resultActions.find((item) => item.value === value)
          return (
            <Chip
              label={resultAction?.label}
              color={resultAction?.chipColor}
              variant="outlined"
              size="small"
            />
          )
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value) => (
          <Grid container direction="row" wrap="nowrap">
            <Grid item>
              <Tooltip title="Edit" disableFocusListener>
                <IconButton onClick={() => handleEditForm(value)} size="small">
                  <EditIcon sx={{ size: 'small' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Delete" disableFocusListener>
                <IconButton
                  size="small"
                  aria-label="delete"
                  onClick={() => {
                    handleDelete(value);
                  }}
                >
                  <DeleteIcon sx={{ size: 'small' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
    },
  ];

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filter: false,
    search: false,
    print: false,
    download: true,
    viewColumns: false,
    rowsPerPage: pageSize,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    jumpToPage: true,
    sortOrder,
    selectableRows: 'none',
    serverSide: true,
    count,
    page,
    fixedHeader: true,
    textLabels: {
      body: {
        noMatch: isLoading ? <LinearProgress /> : 'No matching records found...',
      },
    },
    onTableChange: (action, state) => {
      switch (action) {
        case 'sort':
          resetGridPage();
          setSortOrder(state.sortOrder);
          break;
        case 'changePage':
          resetGridPage(true);
          setPage(state.page);
          break;
        case 'changeRowsPerPage':
          resetGridPage();
          setPageSize(state.rowsPerPage);
          break;
        default:
      }
    },
    customToolbar: AddButton,
  };

  const getReferenceDataField = (idx) => {
    const item = data.results[idx];
    const value =
      item.exceptionRuleType === 'PAN' ? item.additionalReferenceData : item.referenceData;
    return (
      <Grid
        container
        direction="row"
        sx={{
          minWidth: '50px',
          maxWidth: '150px',
          marginLeft: '4px',
          marginRight: '4px',
          overflow: 'hidden',
        }}
      >
        <Grid item>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  const resetGridPage = (skipSetPage = false) => {
    if (!skipSetPage) {
      setPage(0); // move to first page
    }
  };

  const handleDelete = (id) => {
    setIdToDelete(id);
  };

  const confirmDelete = () => {
    deleteExceptionRule(idToDelete);
    setIdToDelete('');
  };

  return (
    <div className="grid-body p-0">
      {isLoading ? (
        <MUIDatatableSkeleton />
      ) : (
        <>
          <Box visibility={isFetching ? 'visible' : 'hidden'}>
            <LinearProgress />
          </Box>
          <MUIDataTable data={exceptionRules} columns={columns} options={options} />
        </>
      )}
      <ConfirmationDialog
        open={!!idToDelete}
        onClose={() => setIdToDelete('')}
        onCancel={() => setIdToDelete('')}
        onConfirm={confirmDelete}
        title="Delete Exception Rule"
        text="Are you sure you want to delete selected Exception Rule?"
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        cancelButtonText="Cancel"
      />
      {exceptionRuleFormOpen && (
        <ExceptionRuleDialog
          open={exceptionRuleFormOpen}
          onClose={() => {
            setExceptionRuleFormOpen(false);
            setDataExceptionRule(cleanExceptionRuleData);
          }}
          productId={productId}
          data={dataExceptionRule}
        />
      )}
    </div>
  );
};
