// npm
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet';

// global modules
import { cleanEmptyQueryParameters } from 'utils/cleanEmptyQueryParameters';
import { queryHasValue } from 'utils/queryHasValue';
import { useGetKycApplicantsQuery } from 'services/gatewayApi/kycApplicantsApi';
import { Box, Table, TableBody, TableHead, TableRow } from '@mui/material';
import {
  DetailsDrawer,
  EndListButton,
  HeadTableCell,
  PageContainer,
  PageControl,
  Skeleton,
  StickyHeader,
  SortBlock,
  SortByHeader,
} from 'uikit';
import { NoResultsBox, NoDataBox } from 'muikit';

import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { SortParam } from 'types/types';

// this module
import { KycApplicantRow } from './KycApplicantRow';
import { KycApplicantDialog } from './KycApplicantDialog';
import { KycApplicantDetails } from './KycApplicantDetails';
import { kycStatuses, extKycStatuses, filterScheme, manualResults, sortOptions } from './constants';

import './KycApplicants.scss';

const defaultPageSize = 20;

const KycApplicants = () => {
  const navigate = useNavigate();

  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<string>('');

  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when searching
  const [searchQuery, setSearchQuery] = useState<string>('');

  const initialFilter = {
    kycStatus: kycStatuses.allIds.filter((status) => status != 'NEW'),
  };
  const [filterQuery, setFilterQuery] = useState<IFilterValue>(initialFilter);
  const [sortedBy, setSortedBy] = useState<SortParam>({
    property: 'createdDate',
    direction: 'desc',
  });

  const filterOptions = {
    kycStatus: kycStatuses?.allIds.map((id) => ({
      value: id,
      label: kycStatuses.byId[id].label,
    })),
    extKycStatus: extKycStatuses?.allIds.map((id) => ({
      value: id,
      label: extKycStatuses.byId[id].label,
    })),
    manualResult: manualResults?.allIds.map((id) => ({
      value: id,
      label: manualResults.byId[id].label,
    })),
  };

  const [pageSize, setPageSize] = useState<number>(defaultPageSize * 2);
  const results = useRef<any>();
  const resultsTotalCount = useRef<number>(0);

  const loader = useRef<HTMLDivElement>();

  const isNoData = useRef<boolean>(false);
  const isNoResults = useRef<boolean>(false);

  // HELPERS
  // Skip multiple queries when typing search field
  const debounceQuery = useCallback(
    debounce(() => setSkipRequest(false), 700),
    [],
  );

  const clearQuery = () => {
    setSearchQuery('');
    setFilterQuery(initialFilter);
  };

  // return correct search object (empty if there is no search query)
  const searchObj = () => (searchQuery ? { search: searchQuery } : {});

  // HANDLERS
  // Search handler
  const searchHandler = (e) => {
    setSkipRequest(true);
    setOpenDetailsDrawer(false);
    setSearchQuery(e);
    debounceQuery();
  };

  // QUERY
  const { data, isLoading, isFetching } = useGetKycApplicantsQuery(
    {
      page: 0,
      size: pageSize,
      sort: `${sortedBy.property},${sortedBy.direction}`,
      ...cleanEmptyQueryParameters(filterQuery),
      ...searchObj(),
    },
    {
      skip: skipRequest,
    },
  );

  // received data
  results.current = data?.content || [];
  resultsTotalCount.current = data?.totalElements || 0;
  const resultsLength = results.current.length;

  // check is there any application data
  isNoData.current = resultsLength === 0 && searchQuery.length === 0 && !queryHasValue(filterQuery);

  // check is there any application data match filter
  isNoResults.current =
    resultsLength === 0 && (searchQuery.length !== 0 || queryHasValue(filterQuery));

  // INITIALISATION
  // restore search and filter value from query
  const { search: urlParams } = useLocation();
  useEffect(() => {
    const searchString = new URLSearchParams(urlParams).get('search');
    setSearchQuery(searchString || '');

    // Read each filter value for filters
    const urlFilters = {};
    filterScheme.map(({ field, name }) => {
      const filterValue = new URLSearchParams(urlParams).get(name);
      if (filterValue) {
        urlFilters[name] = field === 'selectMulti' ? filterValue.split(',') : filterValue;
      }
    });

    setFilterQuery(Object.keys(urlFilters).length > 0 ? urlFilters : initialFilter);
  }, [filterScheme]);

  // load next page method
  const nextPage = () => {
    if (!(isLoading || isFetching) && resultsLength !== resultsTotalCount.current) {
      setPageSize((prevPageSize) => {
        const nextPageSize = prevPageSize + defaultPageSize;
        return nextPageSize >= resultsTotalCount.current ? resultsTotalCount.current : nextPageSize;
      });
    }
  };

  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };
    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  // infinity scroll hook
  useInfinityScroll({ isFetching, isLoading, nextPage, loader });

  // control search, sort, filter from inputs
  useEffect(() => {
    setOpenDetailsDrawer(false); // Close details drawer
    setSelectedItemId(''); // Clear selected item

    const params = new URLSearchParams();
    if (searchQuery) {
      params.append('search', searchQuery);
    }

    params.append('orderBy', sortedBy.property);
    params.append('direction', sortedBy.direction);

    Object.keys(filterQuery).map((key) => {
      params.append(key, filterQuery[key] as string);
    });

    navigate({ search: params.toString() }, { state: true });
  }, [searchQuery, sortedBy, navigate, filterQuery]);

  return (
    <Box className="KycApplicants">
      <Helmet title="KYC Applicants" />
      <PageContainer>
        <StickyHeader>
          <PageControl
            title="KYC Applicants"
            primaryActionTitle="New KYC Applicant"
            primaryAction={() => setShowCreateDialog(true)}
            searchValue={searchQuery}
            searchHandler={searchHandler}
            filtersSelectable
            filterScheme={filterScheme}
            filterValue={filterQuery}
            filterHandler={setFilterQuery}
            options={filterOptions}
            onFilterReset={clearQuery}
          />

          <SortByHeader
            count={resultsLength || 0}
            totalCount={resultsTotalCount.current}
            sortOptions={sortOptions}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
          />
        </StickyHeader>

        <DetailsDrawer open={openDetailsDrawer}>
          <KycApplicantDetails id={selectedItemId} onClose={() => setOpenDetailsDrawer(false)} />
        </DetailsDrawer>

        {/* Loading animation */}
        {isLoading && <Skeleton count={20} height={56} />}

        {!isLoading && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  {sortOptions.map((option) => (
                    <HeadTableCell key={option.fieldName}>
                      <SortBlock
                        property={option.fieldName}
                        sortedBy={sortedBy}
                        handleSort={handleSort}
                      >
                        {option.label}
                      </SortBlock>
                    </HeadTableCell>
                  ))}
                  <HeadTableCell sx={{ width: '1%' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {results.current &&
                  results.current.map((item) => (
                    <KycApplicantRow
                      key={item.id}
                      item={item}
                      selected={item.id === selectedItemId && openDetailsDrawer}
                      setSelectedItemId={setSelectedItemId}
                      setOpenDetailsDrawer={setOpenDetailsDrawer}
                    />
                  ))}
              </TableBody>
            </Table>

            <EndListButton
              isVisible={resultsLength > 0}
              nextPage={nextPage}
              isFetching={isFetching}
              isEndReached={resultsLength === resultsTotalCount.current}
            />
          </>
        )}

        {!(isLoading || isFetching) && (
          <>
            <NoDataBox
              show={isNoData.current}
              entityTitle="KYC Applicant"
              onCreate={() => setShowCreateDialog(true)}
            />
            <NoResultsBox show={isNoResults.current} onResetFilters={clearQuery} />
          </>
        )}
      </PageContainer>

      <div ref={loader} />

      {showCreateDialog && (
        <KycApplicantDialog setShowDialog={setShowCreateDialog} showDialog={showCreateDialog} />
      )}
    </Box>
  );
};

export { KycApplicants };
