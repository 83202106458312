import React, { ChangeEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import { Box, Button, Divider, FormControlLabel, Switch } from '@mui/material';
import { RiArrowGoBackFill } from 'react-icons/ri';
import {
  useGetApplicationHistoryQuery,
  useGetApplicationQuery,
  useUpdateApplicationPartialMutation,
} from 'services/gatewayApi/applicationApi';
import { useGetApplicationFormsQuery } from 'services/gatewayApi/applicationFormsApi';
import { useGetAppFormSectionsQuery } from 'services/gatewayApi/applicationFormSectionsApi';
import { checkProductName } from 'utils';
import { availableSections, formTypes } from '../../available-services';
import { ApplicationSeparator } from '../../ApplicationSeparator';
import { ServiceContainer } from '../../ServiceContainer';
import { AddFormsMenu } from '../../AddFormsMenu';
import { SectionMenu } from '../../SectionMenu';
import { EnableNewFormsSwitch } from './EnableNewFormsSwitch';
import { Section } from '../../Section';

export const AppTab = () => {
  const navigate = useNavigate();

  const { clientId, applicationId } = useParams<{
    applicationId: string;
    clientId: string;
  }>();

  const applicationUrl = `/application-management/${clientId}/applications/${applicationId}`;

  const [availableSectionsWithForms, setAvailableSectionsWithForms] = useState([]);

  const { data: applicationDetails, isLoading: isLoadingApplication } = useGetApplicationQuery(
    applicationId,
    {
      skip: !applicationId,
    },
  );
  const { data: forms, isLoading: isLoadingForms } = useGetApplicationFormsQuery(
    { applicationId },
    { skip: !applicationId },
  );
  const { data: allFormSections, isLoading: isLoadingSections } = useGetAppFormSectionsQuery(
    {
      applicationId,
    },
    { skip: !applicationId },
  );

  const [patchApplication, { isLoading: isPatchingApplication }] =
    useUpdateApplicationPartialMutation();

  const patchApplicationProperty = (property, value) =>
    patchApplication({ id: applicationId, [property]: value })
      .unwrap()
      .catch((rejected) => console.error(rejected));

  // #region isIdle switch
  const disableIsIdle =
    isPatchingApplication || applicationDetails?.mainStatus === 'PENDING_SALES_TEAM_FINAL';
  // #endregion

  // #region clientCanTransact switch
  const { id: userId } = useSelector(makeUserData());
  const { data: applicationHistory = [], isLoading: isLoadingApplicationHistory } =
    useGetApplicationHistoryQuery(applicationId, {
      skip: !applicationId,
    });

  const lastAppHistoryEvent =
    !isLoadingApplicationHistory && applicationHistory.length > 0 && applicationHistory[0];

  const disableClientCanTransact =
    isPatchingApplication ||
    applicationDetails?.clientCanTransact ||
    lastAppHistoryEvent?.decision !== 'CONDITIONAL_APPROVE' ||
    lastAppHistoryEvent?.modifiedBy !== userId;
  // #endregion

  const getFormSectionsWithPartitions = (showInternal) => {
    const results = [];

    allFormSections.map(({ partition, sectionDetails, sectionId, status }) => {
      const { title, sectionOrder } = sectionDetails;
      const formSections = forms?.filter(
        (form) => form.partitionName == partition && form.formSection.id == sectionId,
      );
      const form = formSections[0];

      if (form && form.isInternal === showInternal) {
        results.push({
          link: `forms/${sectionId}/${partition}/${title.toLowerCase().replaceAll(' ', '-')}`,
          title,
          titleAction: (
            <SectionMenu
              applicationId={applicationId}
              sectionId={sectionId}
              partition={partition}
            />
          ),
          order: sectionOrder,
          sectionId,
          partition,
          formId: form?.id,
          status,
          detailedButton: true,
        });
      }
    });

    return results;
  };

  useEffect(() => {
    if (applicationDetails && forms && allFormSections && !isLoadingForms) {
      const makeAvailableSectionsWithForms = availableSections.map((service) => {
        for (const formType of Object.values(formTypes)) {
          if (service.title === formType.title) {
            return {
              title: service.title,
              ...(checkProductName(['OPENPAYD']) &&
                formType.showAddOptionalForms && {
                titleActions: (
                  <Box
                    display="flex"
                    flexGrow="1"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <AddFormsMenu applicationId={applicationId} formSections={allFormSections} />
                    <EnableNewFormsSwitch />
                  </Box>
                ),
              }),
              data: [...service.data, ...getFormSectionsWithPartitions(formType.isInternal)],
            };
          }
        }

        return service;
      });
      setAvailableSectionsWithForms(makeAvailableSectionsWithForms);
    }
  }, [applicationDetails, forms, allFormSections]);

  const isLoading = isLoadingApplication || isLoadingSections;

  const currentPathDepth = location.pathname.split('/').length;

  return (
    <>
      {currentPathDepth === 6 ? (
        <>
          {false && ( // temporarily hidden
            <ApplicationSeparator title="Manage the Client">
              <ServiceContainer>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={disableIsIdle}
                      checked={applicationDetails?.isIdle}
                      onChange={(e: ChangeEvent, checked: boolean) =>
                        patchApplicationProperty('isIdle', checked)
                      }
                    />
                  }
                  label="Idle application"
                  sx={{ mb: 2 }}
                />
              </ServiceContainer>
              <ServiceContainer>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={disableClientCanTransact}
                      checked={applicationDetails?.clientCanTransact}
                      onChange={(e: ChangeEvent, checked: boolean) =>
                        patchApplicationProperty('clientCanTransact', checked)
                      }
                    />
                  }
                  label="Able to transact"
                  sx={{ mb: 2 }}
                />
              </ServiceContainer>
              <ServiceContainer>
                <FormControlLabel
                  control={
                    <Switch
                      checked={applicationDetails?.isEscalated}
                      onChange={(e: ChangeEvent, checked: boolean) =>
                        patchApplicationProperty('isEscalated', checked)
                      }
                    />
                  }
                  label="Escalate"
                  sx={{ mb: 2 }}
                />
              </ServiceContainer>
            </ApplicationSeparator>
          )}

          {!isLoading && availableSectionsWithForms
            .filter((s) => s.title !== 'Documents')
            .map((section) =>
              <Section forms={forms} section={section} applicationDetails={applicationDetails} />
            )}
        </>
      ) : (
        <div style={{ paddingTop: '1em' }}>
          <Button
            variant="contained"
            color="base"
            onClick={() => navigate(`${applicationUrl}/app`)}
            type="button"
            startIcon={<RiArrowGoBackFill size="16px" />}
            sx={{ bgcolor: 'grey.50' }}
          >
            Back
          </Button>

          <Divider sx={{ mt: '1em' }} />

          <Outlet />
        </div>
      )}
    </>
  );
};
