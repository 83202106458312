import PropTypes from 'prop-types';
import React from 'react';
import ReactJson from 'react-json-view';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { RiArrowRightUpFill } from 'react-icons/ri';
import { checkProductName } from 'utils';
import { toastCopySuccess, toastCopyError } from 'utils/toast';
import conditionCodes from '../../constants/conditionCodes';

// ellipsis
const getLastWord = (val) => {
  const splittedWord = val.split(' ');
  const { length } = splittedWord;
  return splittedWord[length - 1];
};

const TransactionItemDetails = ({ item: { key, value } }) => {
  const isJsonString = typeof value === 'string' && value.indexOf('{') === 0;

  const isTech2Pay = checkProductName(['TECHTOPAY']);

  return (
    <tr>
      <td>
        <b>{key.trim()}</b>
      </td>
      <td>
        {(() => {
          // Arrays or JSON strings
          if (Array.isArray(value) || isJsonString) {
            let jsonValue = value;
            if (isJsonString) {
              try {
                jsonValue = JSON.parse(value);
              } catch {
                /* ignore errors */
              }
            }

            return (
              <ReactJson
                src={jsonValue}
                collapsed
                name={getLastWord(key)}
                displayObjectSize={false}
                enableClipboard={false}
                displayDataTypes={false}
              />
            );
          }

          switch (key.trim()) {
            case 'conditionCode': {
              const conditionCode = conditionCodes.find((item) => item.conditionCode === value);
              return (
                <span data-tip={conditionCode?.description}>
                  <ReactTooltip html place="top" />
                  {value}
                </span>
              );
            }

            case 'hashedCardNumber':
              return (
                <div className="hashedNumber">
                  <span className="ellipsis">{value}</span>
                  <a
                    className="success"
                    onClick={() => navigator.clipboard.writeText(value).then(
                      () => toastCopySuccess('Hashed Card Number'),
                      () => toastCopyError('Hashed Card Number'),
                    )}
                    title="Copy to clipboard"
                  >
                    <i className="far fa-clone icon-sm" />
                  </a>
                </div>
              );

            case 'customer session':
              return (
                <div className="customerSession">
                  <span className="ellipsis">{value}</span>
                </div>
              );

            case 'customer email':
              return (
                <>
                  {value}
                  {isTech2Pay && value && (
                    <Tooltip title="Show transactions">
                      <Link to={`/transactions?clientEmail=${encodeURI(value)}`}>
                        <IconButton sx={{ ml: 2 }}>
                          <RiArrowRightUpFill size="14px" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                </>
              );

            default:
              return value.toString().length ? value.toString() : '-';
          }
        })()}
      </td>
    </tr>
  );
};

TransactionItemDetails.propTypes = {
  item: PropTypes.object,
};

export default TransactionItemDetails;
