import { FilterScheme, ValueLabel } from 'types';
import { RuleVetoType } from '../Rules/types';

export type ProductName = 'BANK' | 'GATEWAY' | 'WALLET' | 'CRYPTO' | null;

interface RuleType {
  value: RuleVetoType;
  label: string;
  tenant?: number | null;
  product: ProductName;
}

export const ruleTypes: RuleType[] = [
  { value: 'ACCOUNT_BENEFICIARY', label: 'Beneficiary Account', tenant: 3, product: 'BANK' },
  { value: 'BIC_CODE_BENEFICIARY', label: 'Beneficiary BIC Code', tenant: 3, product: 'BANK' },
  { value: 'BENEFICIARY_COUNTRY', label: 'Beneficiary Country', tenant: 3, product: 'BANK' },
  { value: 'BENEFICIARY_IBAN', label: 'Beneficiary IBAN', tenant: 3, product: 'BANK' },
  { value: 'BENEFICIARY_NAME', label: 'Beneficiary Name', tenant: 3, product: 'BANK' },
  { value: 'DOUBLE_IBAN', label: 'Double IBAN', tenant: 3, product: 'BANK' },
  { value: 'SORT_CODE_BENEFICIARY', label: 'Beneficiary Sort Code', tenant: 3, product: 'BANK' },
  { value: 'BILLING_COUNTRY', label: 'Billing Country', tenant: 1, product: 'GATEWAY' },
  { value: 'COUNTRY', label: 'Country', tenant: 1, product: 'GATEWAY' },
  { value: 'BIN', label: 'BIN', tenant: 1, product: 'GATEWAY' },
  { value: 'BIN_COUNTRY', label: 'BIN Country', tenant: 1, product: 'GATEWAY' },
  { value: 'CLIENT_ID', label: 'Client ID', tenant: 3, product: 'BANK' },
  { value: 'EMAIL', label: 'Email', tenant: 1, product: 'GATEWAY' },
  { value: 'FULL_NAME', label: 'Full Name', tenant: 1, product: 'GATEWAY' },
  { value: 'IP_ADDRESS', label: 'IP Address', tenant: 1, product: 'GATEWAY' },
  { value: 'DEVICE_ID', label: 'Device ID', tenant: 1, product: 'GATEWAY' },
  { value: 'PAN', label: 'PAN', tenant: 1, product: 'GATEWAY' },
  { value: 'RISK_WORD', label: 'Risk Word', tenant: 3, product: 'BANK' },
  { value: 'ACCOUNT_SENDER', label: 'Sender Account', tenant: 3, product: 'BANK' },
  { value: 'BIC_CODE_SENDER', label: 'Sender BIC Code', tenant: 3, product: 'BANK' },
  { value: 'REFERENCE_TEXT', label: 'Reference Text Risk Word', tenant: 3, product: 'BANK' },
  { value: 'SENDER_COUNTRY', label: 'Sender Country', tenant: 3, product: 'BANK' },
  { value: 'SENDER_IBAN', label: 'Sender IBAN', tenant: 3, product: 'BANK' },
  {
    value: 'SENDER_BENEFICIARY_IBAN',
    label: 'Sender Beneficiary IBAN',
    tenant: 3,
    product: 'BANK',
  },
  { value: 'SENDER_NAME', label: 'Sender Name', tenant: 3, product: 'BANK' },
  { value: 'SORT_CODE_SENDER', label: 'Sender Sort Code', tenant: 3, product: 'BANK' },
  { value: 'PHONE_NUMBER', label: 'Phone Number', tenant: 1, product: 'GATEWAY' },
  { value: 'HASHED_CARD_NUMBER', label: 'Hashed Card Number', tenant: 1, product: 'GATEWAY' },
  { value: 'BANK_NAME', label: 'Bank Name', tenant: 1, product: 'GATEWAY' },
];

export const resultActions = [
  { value: 'PASS', label: 'Pass', chipColor: 'success' },
  { value: 'REJECT', label: 'Reject', chipColor: 'error' },

  { value: 'LOWRISK', label: 'Low Risk', chipColor: 'info' },
  { value: 'MEDIUMRISK', label: 'Medium Risk', chipColor: 'warning' },
  { value: 'HIGHRISK', label: 'High Risk', chipColor: 'error' },
];

export const cleanExceptionRuleData = {
  additionalReferenceData: '',
  comment: '',
  createdBy: '',
  createdDateTime: '',
  description: '',
  exceptionRuleType: '',
  expiryDateTime: null,
  id: null,
  merchantName: '',
  referenceData: '',
  resultAction: 'PASS',
};

export const filterScheme: FilterScheme[] = [
  {
    field: 'selectMulti',
    name: 'exceptionRuleTypes',
    label: 'Exception Rule Type',
    initialFilterValue: [],
  },
  {
    field: 'text',
    name: 'accountBeneficiary',
    label: 'Beneficiary Account Number',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'bicCodeBeneficiary',
    label: 'Beneficiary BIC Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'beneficiaryCountry',
    label: 'Beneficiary Country',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'beneficiaryIban',
    label: 'Beneficiary IBAN',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'sortCodeBeneficiary',
    label: 'Beneficiary Sort Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'bin',
    label: 'BIN',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'select',
    name: 'binCountry',
    label: 'BIN Country',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'clientId',
    label: 'Client ID',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'billingCountry',
    label: 'Billing Country',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'select',
    name: 'country',
    label: 'Country',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'email',
    label: 'Email',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'exceptionRuleId',
    label: 'Exception Rule ID',
  },
  {
    field: 'text',
    name: 'fullName',
    label: 'Full Name',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'ipAddress',
    label: 'IP Address',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'select',
    name: 'merchantId',
    label: 'Merchant',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'pan',
    label: 'PAN',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'select',
    name: 'resultAction',
    label: 'Result Action',
  },
  {
    field: 'text',
    name: 'accountSender',
    label: 'Sender Account Number',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'bicCodeSender',
    label: 'Sender BIC Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'senderCountry',
    label: 'Sender Country',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderIban',
    label: 'Sender IBAN',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'sortCodeSender',
    label: 'Sender Sort Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'deviceId',
    label: 'Device ID',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'phoneNumber',
    label: 'Phone Number',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'hashedCardNumber',
    label: 'Hashed Card Number',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'bankName',
    label: 'Bank Name',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'riskWord',
    label: 'Risk Word',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderName',
    label: 'Sender Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'beneficiaryName',
    label: 'Beneficiary Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'referenceText',
    label: 'Reference Text Risk Word',
    tenant: 3,
    product: 'BANK',
  },
];
