import React, { useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { KycApplicant } from '../../types';
import { detailsFields, documentTypes } from '../../constants';
import { DescriptionTags } from '../DescriptionTags';
import { ManualDecisionDialog } from './ManualDecisionDialog';

interface DetailsTabProps {
  kycApplicant: KycApplicant;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ kycApplicant }) => {
  const { id: applicantId, extDescription, manualResult } = kycApplicant;
  const { countries = [], isLoadingCountries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countries: data?.map((c) => ({
        value: c.countryCode,
        label: c.name,
      })),
      isLoading: isLoadingCountries,
    }),
  });

  const [showDecisionDialog, setShowDecisionDialog] = useState(false);
  const [decisionDetails, setDecisionDetails] = useState(null);
  const handleEdit = (applicantId, decision = '') => {
    setDecisionDetails({ applicantId, decision });
    setShowDecisionDialog(true);
  };

  const RenderValue = ({ property }) => {
    const emptyValue = <Typography>-</Typography>;
    const value = kycApplicant[property];

    switch (property) {
      case 'applicationId':
        return value ? (
          <>
            {value}
            <IconButton
              size="small"
              onClick={() => window.open(`/application-management/${value}/id-verify`, '_blank')}
              sx={{ ml: 2 }}
            >
              <OpenInNewIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          </>
        ) : (
          emptyValue
        );

      case 'created':
      case 'modified':
        const date = kycApplicant[`${property}Date`];
        const name = kycApplicant[`${property}ByName`];

        return (
          <Typography>
            {formatIsoDate(date, DATE_FORMAT_MESSAGES)} by {name ?? 'SYSTEM'}
          </Typography>
        );

      case 'dob':
        return <Typography>{formatIsoDate(value, DATE_FORMAT_MESSAGES)}</Typography>;

      case 'nationality':
      case 'docIssuingCountryCode':
      case 'residenceCountryCode':
      case 'country':
        return <Typography>{countries?.find((c) => c.value === value)?.label ?? '-'}</Typography>;

      case 'documentType':
        return (
          <Typography>{documentTypes?.find((c) => c.value === value)?.label ?? '-'}</Typography>
        );

      default:
        return value ?? emptyValue;
    }
  };

  return (
    <>
      {!isLoadingCountries && (
        <>
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" component="span">
              Basic Information
            </Typography>
            {detailsFields.map((field) => (
              <Box
                key={field.value}
                sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
              >
                <Typography variant="body2" sx={{ pt: 2, pb: 1, color: 'grey.600' }}>
                  {field.label}
                </Typography>
                <Box sx={{ pt: 0, pb: 2, color: 'grey.900', typography: 'body1' }}>
                  <RenderValue property={field.value} />
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ mb: 4 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography variant="subtitle1" component="span">
                KYC Status Description
              </Typography>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                  color="primary"
                  onClick={() => handleEdit(applicantId, manualResult)}
                  variant="outlined"
                >
                  {manualResult ? 'Edit' : 'Add'} Manual Status
                </Button>

                {showDecisionDialog && (
                  <ManualDecisionDialog
                    setShowDialog={setShowDecisionDialog}
                    showDialog={showDecisionDialog}
                    data={decisionDetails}
                  />
                )}
              </Stack>
            </Stack>

            <DescriptionTags description={extDescription} />
          </Box>
        </>
      )}
    </>
  );
};
